/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import TextField from '@material-ui/core/TextField';
import { useTranslation } from '@i18n';
import clsx from 'clsx';

import useStyles from '@modules/commons/TextField/style';

const CustomTextField = (props) => {
    const { t } = useTranslation(['common']);
    const classes = useStyles();
    const {
        variant = 'standard',
        InputProps,
        inputProps,
        disabled,
        ...other
    } = props;

    const classNames = [classes.fieldInput];

    if (InputProps?.className) classNames.push(InputProps.className);

    return (
        <TextField
            disabled={disabled}
            variant={variant}
            InputProps={{
                ...InputProps,
                className: clsx(classNames.join(' ')),
                autoComplete: 'no-autocomplete',
            }}
            inputProps={{
                ...inputProps,
                autoComplete: 'no-autocomplete',
            }}
            {...other}
        />
    );
};

export default CustomTextField;
